<template>
  <SubHeader>
    Tap Overview
    <template #right><BadgeBtn @click="openLocalTap()"> + </BadgeBtn></template>
  </SubHeader>
  <CardBox>
    <BTable
      :header="['', 'Name', 'Size', 'Tapped', 'Left in tank', 'Status', 'Supplier', 'Free', 'Used']"
      :footer="['sum', , , tappedSum + ' L', 'todo', , , , ,]"
    >
      <tr v-for="(item, index) in popBrew.containers" :key="index">
        <TapTableItem :item="item" />
      </tr>
    </BTable>
  </CardBox>
</template>

<script lang="ts">
import { PopBrew } from "@/typings/types"
import { defineComponent, PropType } from "@vue/runtime-core"
import { mapActions } from "vuex"
import comp from "../base"
import TapTableItem from "./TapTableItem.vue"

export default defineComponent({
  name: "TapOvervirew",
  props: {
    popBrew: {
      type: Object as PropType<PopBrew>,
      required: true,
    },
  },
  components: {
    ...comp,
    TapTableItem,
  },

  methods: {
    ...mapActions(["openLocalTap"]),
  },
  computed: {
    tappedSum(): number {
      let sum = this.popBrew.containers.reduce((acc, item) => (acc += item.quantity * item.size), 0)
      return Math.round(sum * 100) / 100
    },
  },
})
</script>
