<template>
  <td>
    <ButtonGroup>
      <Button @click="del()"> trash</Button>
    </ButtonGroup>
  </td>
  <td v-for="(callback, index) in dataCallbacl" :key="index">{{ callback(item) }}</td>
  <td>
    <FormInputEnableable
      ref="formQuantity"
      type="number"
      :validateion="validNum"
      v-model.number="quantity"
      @lock="setQuantity()"
      :span="item.unitText"
    />
  </td>
</template>

<script>
import comp from "../base"
import cloneDeep from "clone-deep"
import { apiSetBrew } from "../../services/api"
import { checkValidity, formError, validNum } from "../../services/validation"
import { useStore } from "@/store/store"

export default {
  name: "TapTableItem",
  props: ["item"],
  components: {
    ...comp,
  },
  data() {
    return {
      dataCallbacl: [
        item => item.name,
        item => item.size + item.unitText,
        item => Math.round(item.quantity * item.size * 10) / 10 + item.unitText,
        item => item.unitText,
        item => item.stateText,
        item => item.supplierName,
        item => item.total,
      ],
      brewId: this.$route.params.id,
      quantity: null,
    }
  },
  setup() {
    return {
      validNum,
    }
  },
  created() {
    this.setData()
  },
  watch: {
    item() {
      this.setData()
    },
  },

  methods: {
    setData() {
      if (this.item) {
        this.quantity = this.item.quantity
      }
    },

    del() {
      let local = cloneDeep(useStore().state.collections.brews[this.brewId])
      let index = local.containers.map(item => item.id).indexOf(this.item.id)
      local.containers.splice(index, 1)
      apiSetBrew(local)
    },

    setQuantity() {
      if (checkValidity(this.$refs.formQuantity)) {
        let local = cloneDeep(useStore().state.collections.brews[this.brewId])
        local.containers.find(item => item.id == this.item.id).quantity = this.quantity
        apiSetBrew(local)
      } else formError()
    },
  },
}
</script>

<style></style>
