<template>
  <ModalBase :close="closeLocal" :modalName="'+ Container'" :openModal="openContainer">
    <template #header>Add Container</template>
    <input class="form-control" placeholder="search (TODO)" />
    <table class="table table-hover ">
      <thead>
        <tr>
          <th v-for="(item, index) in header" :key="index">{{ item }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in popContainers" :key="index">
          <td><Button @click="add(item.id)"> add</Button></td>
          <td v-for="(callback, index) in dataCallbacl" :key="index">{{ callback(item) }}</td>
        </tr>
      </tbody>
    </table>
    <!-- {{ idsAdded }} -->
  </ModalBase>
</template>

<script>
import comp from "../base"
import cloneDeep from "clone-deep"
import { mapActions } from "vuex"
import ModalBase from "../modals/ModalBase.vue"
// import { CATEGORY } from "../../services/enums"
import { apiSetBrew } from "../../services/api"
import { useStore } from "@/store/store"

export default {
  name: "MashingModal",
  props: ["popBrew"],
  components: {
    ...comp,
    ModalBase,
  },
  data() {
    return {
      header: ["add", "name", "size", "supplier", "free"],
      dataCallbacl: [
        item => item.name,
        item => item.size + " " + item.unitText,
        item => item.supplierName,
        item => item.total,
      ],
    }
  },
  computed: {
    popContainers() {
      return useStore().getters.popContainers.filter(item => !this.added.includes(item.id))
    },
    added() {
      return this.popBrew.containers.map(item => item.id)
    },
  },
  methods: {
    ...mapActions(["openContainer"]),
    ...mapActions(["closeLocal"]),
    add(id) {
      let brew = cloneDeep(useStore().state.collections.brews[this.popBrew.id])
      brew.containers.push({
        id: id,
      })
      apiSetBrew(brew)
      this.closeLocal()
    },
  },
}
</script>
