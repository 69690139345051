<template>
  <Container>
    <Header>
      Tapping Sheet | {{ popBrew.formatCount }} <strong> {{ popBrew.recipeName }} </strong>
    </Header>
    <PageContent>
      <TapTable :popBrew="popBrew" />
      <template #right>
        <BrewRecyme :popBrew="popBrew" />
      </template>
    </PageContent>
  </Container>
  <TapModal v-if="modal" :popBrew="popBrew" />
</template>

<script>
import { computed } from "vue"
import { useRoute } from "vue-router"
import comp from "../base"
import BrewRecyme from "../recymes/BrewRecyme.vue"
import TapTable from "./TapTable.vue"
import TapModal from "./TapModal.vue"
import { useStore } from "@/store/store"

export default {
  name: "TapSheet",
  components: {
    ...comp,
    BrewRecyme,
    TapTable,
    TapModal,
  },
  setup() {
    return {
      popBrew: computed(() => useStore().getters.popBrew(useRoute().params.id)),
      modal: computed(() => useStore().state.localModals.tap),
    }
  },
}
</script>
