
import { PopBrew } from "@/typings/types"
import { defineComponent, PropType } from "@vue/runtime-core"
import { mapActions } from "vuex"
import comp from "../base"
import TapTableItem from "./TapTableItem.vue"

export default defineComponent({
  name: "TapOvervirew",
  props: {
    popBrew: {
      type: Object as PropType<PopBrew>,
      required: true,
    },
  },
  components: {
    ...comp,
    TapTableItem,
  },

  methods: {
    ...mapActions(["openLocalTap"]),
  },
  computed: {
    tappedSum(): number {
      let sum = this.popBrew.containers.reduce((acc, item) => (acc += item.quantity * item.size), 0)
      return Math.round(sum * 100) / 100
    },
  },
})
